import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Col, Container, Row } from "reactstrap";
import styled from "styled-components";
import { Bamboo, CoconutOil, Ginseng } from "../../components/library/Icons";
import { Header } from "../../components/library/Text";

const Section = styled.div`
  background-color: #bfebf8; ;
`;

const Wrapper = styled.div`
  max-width: 1230px;
  width: 100%;
  margin: 0 auto;
  padding: 61px 15px 122px 15px;
`;

const Feature = styled(Header)`
  color: #090c0e;

  @media screen and (max-width: 768px) {
    font-size: 2rem;
  }
`;

const Highlight = styled.div`
  font-family: Quarto;
  font-size: 36px;
  font-style: normal;
  font-weight: bold;
  line-height: 50px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 1rem;

  span {
    color: #93b1c3;
  }

  @media screen and (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const ImgContainer = styled.div`
  border: 4px solid #8fd4f1;
  box-shadow: 20px 20px #8fd4f1;

  @media screen and (max-width: 400px) {
    box-shadow: 10px 10px #8fd4f1;
  }

  @media screen and (max-width: 768px) {
    margin: 0 auto;
    max-height: 343px;
    max-width: 343px;
  }
`;

const Info = styled.div`
  padding-left: 34px;

  @media screen and (max-width: 768px) {
    padding-left: 0;
    padding-top: 40px;
  }
`;

const SVG = styled.div`
  margin-bottom: 14px;

  @media screen and (max-width: 768px) {
    text-align: center;
  }
`;

const Ingredient = styled.p`
  font-family: "Gill Sans";
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0.02em;
  color: rgba(0, 0, 0, 0.8);

  @media screen and (max-width: 768px) {
    text-align: center;
  }
`;

const IngredientInfo = styled.p`
  font-family: "Gill Sans";
  font-size: 20px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0.02em;
  color: rgba(0, 0, 0, 0.4);

  :last-child {
    margin-bottom: 0;
  }

  @media screen and (min-width: 769px) {
    margin-bottom: 58px;
  }
`;

const IngredientFeature = () => {
  return (
    <Section>
      <Wrapper>
      <Feature>Formulated for your Hair</Feature>
      <Highlight>
        All of our products are created with ingredients like <span>ginseng</span>,{" "}
        <span>bamboo</span>, and <span>coconut extract</span> that are found in nature to maximize
        results for curly & textured hair
      </Highlight>
        <Container fluid>
          <Row>
            <Col md={6}>
              <ImgContainer>
                <StaticImage
                  src="https://static.wavyformula.com/images/wavy-coconut.jpg"
                  alt="Coconut"
                />
              </ImgContainer>
            </Col>
            <Col md={6}>
              <Info>
                <SVG>
                  <Ginseng />
                </SVG>
                <Ingredient>Ginseng</Ingredient>
                <IngredientInfo>
                  Ginseng is made up of compounds called ginsenosides which have been proven to
                  promote hair growth due to their anti-oxidation effects that alter the hair growth
                  cycle. Ginsenosides act as anti-aging agents against UV ray exposure that causes
                  hair loss.
                </IngredientInfo>
                <SVG>
                  <Bamboo />
                </SVG>
                <Ingredient>Bamboo</Ingredient>
                <IngredientInfo>
                  Bamboo is rich in silica and can promote hair growth, helps strengthen hair, and
                  gives hair a healthy, bouncy texture.
                </IngredientInfo>
                <SVG>
                  <CoconutOil />
                </SVG>
                <Ingredient>Coconut Extract</Ingredient>
                <IngredientInfo>
                  Coconut extract has been found to be a beneficial ingredient in hair care products
                  due to its high concentration of lauric acid, which has been shown to penetrate
                  the hair shaft and reduce protein loss. Studies have also suggested that coconut
                  extract can improve hair's strength and flexibility, promote hair growth, and
                  provide nourishment to the scalp, making it an excellent natural option for
                  individuals looking to improve the health of their hair. However, more research is
                  needed to fully understand the potential benefits of using coconut extract on
                  hair.
                </IngredientInfo>
              </Info>
            </Col>
          </Row>
        </Container>
      </Wrapper>
    </Section>
  );
};

export default IngredientFeature;
